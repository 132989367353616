.search-container {

    .search-input {
        background: mat-color($background, 300);
        color: mat-contrast($background, 300);
        caret-color: mat-contrast($background, 300);

        &:focus {
            outline: none;
        }
    }

    .clear-button {
        color: mat-contrast($background, 300);

        &:hover {
            color: mat-color($accent2, default);
        }
    }

    .search-button-container {
        background: mat-color($background, 300);
        color: mat-color($background, contrast-300);
        
        &:hover {
            background: mat-color($accent2, default);
            border-color: mat-color($accent2, default);
        }
    }
}

@media only screen and (max-width: 1000px) {
    .search-button-container {
        background: mat-color($accent2, default) !important;
        border-color: mat-color($accent2, default) !important;
    }
    
    .clear-button {
        color: mat-color($accent2, default) !important;
    }
}