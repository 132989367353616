
// https://paletton.com/#uid=33n0i0kq8Ku1dYedbOoOH-0RPnt

/* For use in src/lib/core/theming/_palette.scss */
$blue-palette: (
    50 : #e5f5f9,
    100 : #bfe7f0,
    200 : #95d7e6,
    300 : #6ac6dc,
    400 : #4abad5,
    500 : #2aaecd,
    600 : #25a7c8,
    700 : #1f9dc1,
    800 : #1994ba,
    900 : #0f84ae,
    A100 : #ddf5ff,
    A200 : #aae6ff,
    A400 : #77d7ff,
    A700 : #5dd0ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$yellow-palette: (
    50 : #fff6e6,
    100 : #ffe8c1,
    200 : #ffd897,
    300 : #ffc86d,
    400 : #ffbd4e,
    500 : #ffb12f,
    600 : #ffaa2a,
    700 : #ffa123,
    800 : #ff981d,
    900 : #ff8812,
    A100 : #ffffff,
    A200 : #fffcf9,
    A400 : #ffe0c6,
    A700 : #ffd3ad,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$orange-palette: (
    50 : #ffeee6,
    100 : #ffd4c1,
    200 : #ffb897,
    300 : #ff9b6d,
    400 : #ff854e,
    500 : #ff702f,
    600 : #ff682a,
    700 : #ff5d23,
    800 : #ff531d,
    900 : #ff4112,
    A100 : #ffffff,
    A200 : #fffaf9,
    A400 : #ffcfc6,
    A700 : #ffbaad,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$background-palette: (
    50 : #fdfefe,
    100 : #fafcfd,
    200 : #f7fafb,
    300 : #f3f8f9,
    400 : #f1f7f8,
    500 : #eef5f7,
    600 : #ecf4f6,
    700 : #e9f2f5,
    800 : #e7f0f3,
    900 : #e2eef1,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat-core();

$primary: mat-palette($yellow-palette);
$accent: mat-palette($blue-palette);
$accent2: mat-palette($orange-palette);
$background: mat-palette($background-palette);
// The warn palette is optional (defaults to red).

$warn: mat-palette($mat-red);

// The "warn" palette is optional and defaults to red if not specified.

$dokicall-theme: mat-light-theme(
    $primary,
    $accent,
    $warn
);

@include angular-material-theme($dokicall-theme);
