/* You can add global styles to this file, and also import other style files */

@import './assets/styles/theme.scss';

@import './assets/styles/components/core/footer.scss';
@import './assets/styles/components/core/scroll-bar.scss';
@import './assets/styles/components/core/terms.scss';
@import './assets/styles/components/core/toolbar.scss';

@import './assets/styles/components/shared/breadcrumbs.scss';
@import './assets/styles/components/shared/button.scss';
@import './assets/styles/components/shared/list-item.scss';
@import './assets/styles/components/shared/search-bar.scss';

html, body {
    height: 100%;
    margin: 0px;
    background: mat-color($background, 300);
    color: mat-color($background, contrast-300);
}

html, body {
  height: 100%;
}

body, button, input {
  font-family: Ubuntu, "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
}

li {
  list-style-type: none;
}

input, button, submit { border:none; } 

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.hidden {
  display: none !important;
}