body::-webkit-scrollbar {
    width: 10px;
}

body {
    scrollbar-width: thin;
    scrollbar-color: mat-color($accent2, default) mat-color($background, 700);
}

body::-webkit-scrollbar-track {
    background: mat-color($background, 700);
}

body::-webkit-scrollbar-thumb {
    background-color: mat-color($accent2, default) ;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: mat-color($background, 700);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: mat-color($accent2, default) ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    filter: brightness(20%);
}