.icon-button {
    background: mat-color($accent, 500);
    border-radius: 5px;
    width: 24px;
    height: 24px;
    padding: 10px;
    position: relative;

    .iconify {
        font-size: 24px;
        color: mat-color($accent, default-contrast) !important;
    }

    &:hover {
        background: mat-color($accent2, 500);
        border-radius: 5px;
        cursor: pointer;
        
        .iconify {
            color: mat-color($background, 500);
        }
    }
    
    &.small {
        width: 20px;
        height: 20px;

        .iconify {
            font-size: 20px;
        }
    }

    &.x-small {
        width: 16px;
        height: 16px;

        .iconify {
            font-size: 20px;
            margin-left: -2px;
            margin-top: -2px;
        }
    }

    &.hidden {
        visibility: hidden;
    }
}

button {
    border-radius: 5px;
    
    &:hover {
        box-shadow: -1px 10px 29px 0px rgba(0,0,0,0.5);
        cursor: pointer;
        filter: brightness(90%);
    }
}