.list-item {
    background: mat-color($background, 300);
    border-radius: 4px;
    border: 1px #00000022;
    border-style: solid;

    .item-container {
        
        .favorite-star {
            color: mat-contrast($background, 700);

            &:hover {
                color: mat-color($accent2, default);
            }
        }

        .favorite-item {
            color: mat-color($accent, default);

            &:hover {
                color: mat-color($accent2, default);
            }
        }

        .item-name {
            color: mat-contrast($background, default);
        }

        .item-extension {
            color: mat-color($accent2, default);
        }

        .item-extension-copy-icon {
            color: mat-contrast($background, default);
        }

        .item-extension-copy-message  {
            color: mat-color($accent2, default);
        }
    }
}

.list-item:hover {
    // background: mat-color($accent2, default);
    -webkit-box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.11); 
    box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.11);

    .item-extension-copy-icon {
        color: mat-color($accent2, default);
    }
}

@media only screen and (max-width: 1000px) {

    .item-extension-copy-icon {
        color: mat-color($accent2, default) !important;
    }

}